(function($) {

/**
* create modal HTML
*/
function modalHTML(content) {
    var html = '<div class="fs-modal"><a class="fs-modal-close" href="#">x</a>';
    html += '<div class="fs-modal-content"><div class="container">';
    html += content;
    html += '</div></div></div>';
    $(html).prependTo('body');
    $('.fs-modal-close').on('click', function(e) {
         e.preventDefault();
        $('.fs-modal').remove();
    });
}

/**
* modal trigger
*/
function fullScreenModal() {
    $('.fs-modal-trigger').each(function () {
        var content = $(this).find('.fs-modal-source').html();
        $(this).on('click', function(e) {
            e.preventDefault();
            modalHTML(content);
        });
    })
}

function responsiveNav(className) {
    $(className).each(function() {
        $(this).on('click', function (e) {
            var targetId = $(this).data('target'),
                targetElem = $('#'+targetId);
            e.preventDefault();
            $(this).toggleClass('active');
            if(targetElem.hasClass('nav-active')) {
                targetElem.removeClass('nav-active');
            } else {
                targetElem.addClass('nav-active');
            }
        })
    })
}

function setNavBg() {
  var elem = $('.header-navbar');
  $(window).scroll(function(){
    if ($(this).scrollTop() > 80) {
      elem.addClass('header-scrolled');
    } else {
      elem.removeClass('header-scrolled');
    }
  });
}

function scrollToTop() {
  $('.scroll-top-btn').click(function(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 600);
  });
}

responsiveNav('.navbar-menu--trigger');
$('.parallax-window').parallax();
setNavBg();

$('.testimonials-slider').owlCarousel({

    navigation : false, // Show next and prev buttons
    slideSpeed : 300,
    paginationSpeed : 400,
    singleItem:true

});

$('.slider').owlCarousel({

    navigation : false, // Show next and prev buttons
    slideSpeed : 300,
    paginationSpeed : 400,
    singleItem:true

});

$('.portfolio-slideshow').owlCarousel({
    navigation : true, // Show next and prev buttons
    slideSpeed : 300,
    paginationSpeed : 400,
    singleItem:true,
    pagination:false
});

var portfolioSlide = $('.portfolio-slideshow').data('owlCarousel');

$('.slideshow-trigger').each(function() {
    $(this).on('click', function(e) {
        e.preventDefault();
        portfolioSlide.jumpTo($(this).data('index'));
        $('.portfolio-slideshow--container').addClass('active');
        $('body').addClass('fs-modal-open');
    })
});

$('.portfolio-slideshow--close').on('click', function (e) {
    e.preventDefault();
    $('.portfolio-slideshow--container').removeClass('active');
    $('body').removeClass('fs-modal-open');
});


//portfolio items filter
if($('body').hasClass('page-template-template-portfolio')) {
    $('#portfolio-grid').waitForImages().done(function() {
        var Shuffle = window.shuffle;
        var myShuffle = new Shuffle(document.getElementById('portfolio-grid'), {
          itemSelector: '.portfolio-grid-item ',
          sizer: '.portofolio-grid-sizer'
        });

        //default to show all items
        myShuffle.filter(Shuffle.ALL_ITEMS);

        $('.filter-button').each(function() {
            $(this).on('click', function(e) {
                 e.preventDefault();
                 $(this).siblings().removeClass('active');
                 
                if( $(this).hasClass('active') || $(this).data('filter') === 'all' ) {
                    myShuffle.filter(Shuffle.ALL_ITEMS);
                }
                
                $(this).toggleClass('active');
                myShuffle.filter($(this).data('filter'));
            })
        });
    });
}

$('.nav-links').each(function() {
  $(this).click(function(e) {
    e.preventDefault();
    var target = $($(this).attr('href')).offset().top;
    $('html, body').animate({
      scrollTop: target
    });
  });
});


fullScreenModal();

})(jQuery);

//scroll reveal
window.sr = ScrollReveal();
sr.reveal('.scroll-reveal', {
    reset: true
});